import * as React from "react";
import { Link } from "gatsby";
import { useTranslation } from "react-i18next";
import "../i18n";

const PostNavigation = ({ prevPost, nextPost }) => {
    const { t } = useTranslation();

    return (
        <nav className="post-nav d-flex justify-content-between">
            <div className="nav-previous">
                {prevPost && (
                    <Link to={`/blog${prevPost.uri}`}>
                        <i className="bi bi-arrow-left mr-1"></i>{t("previous")}{" "}
                        <span className="d-none d-md-inline">{t("post")}</span>
                    </Link>
                )}
            </div>
            <div className="nav-next">
                {nextPost && (
                    <Link to={`/blog${nextPost.uri}`}>
                        {t("next")}{" "}<span className="d-none d-md-inline">{t("post")}</span>
                        <i className="bi bi-arrow-right ml-1"></i>
                    </Link>
                )}
            </div>
        </nav>
    );
};

export default PostNavigation;
